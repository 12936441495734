<template>
  <div ref="outer-wrapper">
    <multiselect ref="dropdown-node"
      :value="selectedOptions"
      @input="updateSelection"
      :options="options" 
      :search="true"
      :max-height="150"
      openDirection="bottom"
      :multiple="true"
      :label="label" 
      :track-by="trackBy"
      selectLabel=""
      deselectLabel=""
      :selectedLabel="checkmarkEmoji"
      :closeOnSelect="false"
    >
    </multiselect> 
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";


export default {
  name: "SingleDropdown",
  components: {
    Multiselect,
  },
  props: {
    label: {
      type: String,
    },
    trackBy: {
      type: String,
    },
    options: {
      type: Array,
    },
    selectedIDs: {
      type: Array,
    }
  },
  data() {
    return {
      checkmarkEmoji: "\u2714\uFE0F",
    }
  },
  methods: {
    registerOnBlurEvent () {
      let node = this.$refs["outer-wrapper"];
      if (node) {
        node = node.getElementsByClassName('multiselect__content-wrapper')[0];
      }
      node.addEventListener("blur", () => {
        this.$refs["dropdown-node"].deactivate();
      })
    },

    // Not to use v-model. We want it to propagate to parent, and pass the prop back
    updateSelection (newVal) {
      this.$emit('selectedIDsChanged', newVal.map(item => {
        return item[this.trackBy];
      }));
    }
  },
  computed: {
    selectedOptions () {
      return this.options.filter(option => {
        return this.selectedIDs.includes(option[this.trackBy]);
      })
    }
  },
  mounted () {
    this.registerOnBlurEvent();
  },
};
</script>