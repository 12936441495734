<template>
  <div> 
  <CCard class='h-100' v-if="!geofenceNotFound">
    <CCardHeader class="m-0 p-1 d-flex justify-content-between" color="light">
      <span> <b> {{title}} </b> </span>
      <CBadge> {{ uid }} </CBadge> 
    </CCardHeader>
    <CCardBody class="overflow-auto">
      <CRow>
        <CCol col="6">
          <div style="height: 500px">
            <SimpleLeafletMap
              :center="outputCenter"
              :marker="outputCenter"
              :radius="outputRadius"
            >
            </SimpleLeafletMap>
          </div>
        </CCol>
        <CCol col="6">
          <CForm>
            <div>
              <CInput
                label="Name"
                type="text"
                placeholder="Type in a name"
                v-model="inputName"
                :isValid="!$v.inputName.$invalid"
                :invalidFeedback="invalidFeedback('inputName')"
              />
            </div>

            <div>
              <CTextarea
                label="Description"
                type="text"
                placeholder="Type in a short description"
                v-model="inputDescription"
                :isValid="!$v.inputDescription.$invalid"
                :invalidFeedback="invalidFeedback('inputDescription')"
              />
            </div>
            <div>
              <CInput
                label="Lat"
                type="text"
                placeholder="Type in a latitude such as 49.34"
                v-model="inputLat"
                :isValid="!$v.inputLat.$invalid"
                :invalidFeedback="invalidFeedback('inputLat')"
              />
            </div>
            <div>
              <CInput
                label="Lng"
                type="text"
                placeholder="Type in a longitude such as -122.325"
                v-model="inputLng"
                :isValid="!$v.inputLng.$invalid"
                :invalidFeedback="invalidFeedback('inputLng')"
              />
            </div>
            <div>
              <CInput
                label="Radius"
                type="text"
                placeholder="Type in a radius measured in meters"
                v-model="inputRadius"
                :isValid="!$v.inputRadius.$invalid"
                :invalidFeedback="invalidFeedback('inputRadius')"
              >
              <template #append-content>
              m
              </template>
              </CInput>
              
            </div>

            <div style="height: 300px">
              <span> Tags Selection </span>
              <SingleDropdown
                :selectedIDs="selectedTagsUIDs" 
                :options="tagOptions"
                trackBy="uid"
                label="name"
                @selectedIDsChanged="setSelectedTags"
              >
              </SingleDropdown>
            </div>
          </CForm>
        </CCol>
      </CRow>
    </CCardBody>
    <CCardFooter>
      <div>
        <div class="float-right">
          <CButton @click="submit" color="success" :disabled="!validated">
            Confirm
          </CButton>
          <CButton @click="cancel">
            Cancel
          </CButton>
        </div>
      </div>
    </CCardFooter>
  </CCard>
  <CCard v-if="geofenceNotFound"> 
    <CCardBody>
      The geofence with UID "{{uid}}" doesn't exist.
      Click 
      <CButton 
        variant="ghost"
        @click="goToMainPage"
      > 
      here 
      </CButton> 
      to go back to the Geofence List Page
    </CCardBody>
  </CCard>
  </div>
</template>

<script>
const minVal = {
  inputLat: -90,
  inputLng: -180,
  inputRadius: 0,
};
const maxVal = {
  inputLat: 90,
  inputLng: 180,
};

const maxLeng = {
  inputName: 100,
  inputDescription: 400,
  inputLat: 25,
  inputLng: 25
}

import { validationMixin } from "vuelidate";
import {
  required,
  between,
  maxLength,
  minValue,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import {
  CircularGeofence,
} from "@/lib/geofencing_lib";
import { manualEditType } from "@/lib/geofencing_lib"
import SingleDropdown from "@/components/filters/SingleDropdown"
import SimpleLeafletMap from "@/components/dashboard/SimpleLeafletMap";


export default {
  components: {
    SingleDropdown,
    SimpleLeafletMap,
  },
  name: "CircularGeofenceTable",
  props: {

  },
  data() {
    return {
      inputDescription: null,
      inputLat: 49.2,
      inputLng: -122.8,
      inputName: null, 
      inputRadius: 10,
      initialSelectedTagsUIDs: [],
      selectedTagsUIDs: [],
      uid: null,
      editType: null,
      outputCenter: [0, 0],
      outputRadius: 0,
    };
  },
  validations: {
    inputDescription: {
      maxLengthValue: maxLength(maxLeng["inputDescription"]),      
    },
    inputName: {
      required,
      maxLengthValue: maxLength(50)
    },
    // Custom Validator Functions for Form Input
    inputLat: {
      required,
      betweenValue: between(minVal["inputLat"], maxVal["inputLat"]),
      maxLengthValue: maxLength(maxLeng["inputLat"])
    },
    inputLng: {
      required,
      betweenValue: between(minVal["inputLng"], maxVal["inputLng"]),
      maxLengthValue: maxLength(maxLeng["inputLng"])
    },
    inputRadius: {
      required,
      minValueValue: minValue(minVal["inputRadius"])
    }
  },
  mixins: [validationMixin],
  methods: {
    submit() {
      const geo = new CircularGeofence(
        null,
        this.inputName,
        Number(this.inputLat),
        Number(this.inputLng),
        Number(this.inputRadius),
        this.selectedTagsUIDs,
        this.inputDescription,
        null,
        null,
        this.uid,
        Number(this.inputOwner),
      )

      let body = geo.castAsApiBody();

      if (this.editType == manualEditType.ADD_CIRCULAR) {
        this.$app.addGeofence(body);
      } else {
        this.$app.modifyGeofence(body, geo.getUId());
      }
      this.goToMainPage();
    },

    cancel() {
      this.goToMainPage();
    },

    setDefaultInputValues() {
      this.inputName = null;
      this.inputDescription = null;
      this.inputLat = 49.2;
      this.inputLng = -122.8;
      this.inputRadius = 10;
      this.selectedTagsUIDs = [];
    },

    goToMainPage () {
      this.$router.push({
        name: "Geofence",
      });
    },

    setSelectedTags (selectedUIDs) {
      this.selectedTagsUIDs = selectedUIDs;
    },

    invalidFeedback (fieldName) {
      if (!this.$v[fieldName].$invalid) {
        return '';
      }
      if (this.$v[fieldName].required == false) {
        return "Cannot be empty";
      } else if (this.$v[fieldName].maxLengthValue == false) {
        return `Cannot exceed length of ${maxLeng[fieldName]}`;
      } else if (this.$v[fieldName].betweenValue == false) {
        return `Enter a value between ${minVal[fieldName]} and ${maxVal[fieldName]}`;
      } else if (this.$v[fieldName].minValueValue == false) {
        return `Enter a value larger than ${minVal[fieldName]}`;
      } else {
        console.log("Need to handle this error case: ", this.$v[fieldName]);
        return 'Error';
      }
    },

    updateOutputCenter () {
      if (this.$v.inputLat.$invalid || this.$v.inputLng.$invalid) {
        return;
      } else {
        this.outputCenter = [this.inputLat, this.inputLng];
      }
    },

    updateOutputRadius () {
      if (this.$v.inputRadius.$invalid) {
        return;
      }
      this.outputRadius = Number(this.inputRadius);
    }
  },
  computed: {
    ...mapGetters({
      circularGeofences: "circularGeofences",
      geofenceCategories: "geofenceCategories",
    }),

    validated() {
      return !this.$v.$invalid;
    },
    title () {
      if (this.editType == manualEditType.ADD_CIRCULAR) {
        return "Add Circular";
      } else {
        return "Modify Circular";
      } 
    },

    geofence () {
      let result;
      if (this.editType == manualEditType.MODIFY_CIRCULAR) {
        result = this.circularGeofences.find(_geo => {
          return _geo.getUId() == this.uid;
        })
      } else if (this.editType == manualEditType.ADD_CIRCULAR) {
        result = null;
      } else {
        console.error('unsupported editType', this.editType);
      }
      return result;
    },

    geofenceNotFound () {
      return !this.geofence && this.editType == manualEditType.MODIFY_CIRCULAR; //h_ms_01
    },

    tagOptions () {
      return this.geofenceCategories.map(tag => {
        return {
          name: tag.getName(),
          uid: tag.getUId(),
        }
      })
    },
},
watch: {
  geofence: {
    immediate: true,
    handler () {
      // console.log("geofence watcher triggered", this.uid, this.geofence);
      if (this.geofence) {
        const latlng = this.geofence.getLatLngCasted();
        this.inputName = this.geofence.getName();
        this.inputDescription = this.geofence.getDescription();
        this.inputLat = latlng[0];
        this.inputLng = latlng[1];
        this.inputRadius = this.geofence.getRadius();
        this.initialSelectedTagsUIDs = this.geofence.getCategories();
      } else {
        this.setDefaultInputValues(); 
      }
    }
  },
  $route: {
    immediate: true,
    handler () {
      // don't change the editType or uid, so that geofence computed prop doesn't trigger.
      // console.log("this.route", this.$route);
      if (this.$route.name == manualEditType.MODIFY_CIRCULAR) {
        this.uid = this.$route.params.uid;
        this.editType = this.$route.name;
      }

      if (this.$route.name == manualEditType.ADD_CIRCULAR) {
        this.uid = null;
        this.editType = this.$route.name;
        this.setDefaultInputValues();
      }
    }
  },

  inputLat: {
    immediate: true,
    handler () {
      this.updateOutputCenter();
    }
  },

  inputLng: {
    immediate: true,
    handler () {
      this.updateOutputCenter();
    }
  },

  inputRadius: {
    immediate: true,
    handler () {
      this.updateOutputRadius();
    }
  }
}
};
</script>
