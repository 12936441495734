var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.geofenceNotFound
        ? _c(
            "CCard",
            { staticClass: "h-100" },
            [
              _c(
                "CCardHeader",
                {
                  staticClass: "m-0 p-1 d-flex justify-content-between",
                  attrs: { color: "light" }
                },
                [
                  _c("span", [
                    _c("b", [_vm._v(" " + _vm._s(_vm.title) + " ")])
                  ]),
                  _c("CBadge", [_vm._v(" " + _vm._s(_vm.uid) + " ")])
                ],
                1
              ),
              _c(
                "CCardBody",
                { staticClass: "overflow-auto" },
                [
                  _c(
                    "CRow",
                    [
                      _c("CCol", { attrs: { col: "6" } }, [
                        _c(
                          "div",
                          { staticStyle: { height: "500px" } },
                          [
                            _c("SimpleLeafletMap", {
                              attrs: {
                                center: _vm.outputCenter,
                                marker: _vm.outputCenter,
                                radius: _vm.outputRadius
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c(
                        "CCol",
                        { attrs: { col: "6" } },
                        [
                          _c("CForm", [
                            _c(
                              "div",
                              [
                                _c("CInput", {
                                  attrs: {
                                    label: "Name",
                                    type: "text",
                                    placeholder: "Type in a name",
                                    isValid: !_vm.$v.inputName.$invalid,
                                    invalidFeedback: _vm.invalidFeedback(
                                      "inputName"
                                    )
                                  },
                                  model: {
                                    value: _vm.inputName,
                                    callback: function($$v) {
                                      _vm.inputName = $$v
                                    },
                                    expression: "inputName"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("CTextarea", {
                                  attrs: {
                                    label: "Description",
                                    type: "text",
                                    placeholder: "Type in a short description",
                                    isValid: !_vm.$v.inputDescription.$invalid,
                                    invalidFeedback: _vm.invalidFeedback(
                                      "inputDescription"
                                    )
                                  },
                                  model: {
                                    value: _vm.inputDescription,
                                    callback: function($$v) {
                                      _vm.inputDescription = $$v
                                    },
                                    expression: "inputDescription"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("CInput", {
                                  attrs: {
                                    label: "Lat",
                                    type: "text",
                                    placeholder:
                                      "Type in a latitude such as 49.34",
                                    isValid: !_vm.$v.inputLat.$invalid,
                                    invalidFeedback: _vm.invalidFeedback(
                                      "inputLat"
                                    )
                                  },
                                  model: {
                                    value: _vm.inputLat,
                                    callback: function($$v) {
                                      _vm.inputLat = $$v
                                    },
                                    expression: "inputLat"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("CInput", {
                                  attrs: {
                                    label: "Lng",
                                    type: "text",
                                    placeholder:
                                      "Type in a longitude such as -122.325",
                                    isValid: !_vm.$v.inputLng.$invalid,
                                    invalidFeedback: _vm.invalidFeedback(
                                      "inputLng"
                                    )
                                  },
                                  model: {
                                    value: _vm.inputLng,
                                    callback: function($$v) {
                                      _vm.inputLng = $$v
                                    },
                                    expression: "inputLng"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("CInput", {
                                  attrs: {
                                    label: "Radius",
                                    type: "text",
                                    placeholder:
                                      "Type in a radius measured in meters",
                                    isValid: !_vm.$v.inputRadius.$invalid,
                                    invalidFeedback: _vm.invalidFeedback(
                                      "inputRadius"
                                    )
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append-content",
                                        fn: function() {
                                          return [_vm._v(" m ")]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    444179610
                                  ),
                                  model: {
                                    value: _vm.inputRadius,
                                    callback: function($$v) {
                                      _vm.inputRadius = $$v
                                    },
                                    expression: "inputRadius"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticStyle: { height: "300px" } },
                              [
                                _c("span", [_vm._v(" Tags Selection ")]),
                                _c("SingleDropdown", {
                                  attrs: {
                                    selectedIDs: _vm.selectedTagsUIDs,
                                    options: _vm.tagOptions,
                                    trackBy: "uid",
                                    label: "name"
                                  },
                                  on: {
                                    selectedIDsChanged: _vm.setSelectedTags
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("CCardFooter", [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "float-right" },
                    [
                      _c(
                        "CButton",
                        {
                          attrs: { color: "success", disabled: !_vm.validated },
                          on: { click: _vm.submit }
                        },
                        [_vm._v(" Confirm ")]
                      ),
                      _c("CButton", { on: { click: _vm.cancel } }, [
                        _vm._v(" Cancel ")
                      ])
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.geofenceNotFound
        ? _c(
            "CCard",
            [
              _c(
                "CCardBody",
                [
                  _vm._v(
                    ' The geofence with UID "' +
                      _vm._s(_vm.uid) +
                      "\" doesn't exist. Click "
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: { variant: "ghost" },
                      on: { click: _vm.goToMainPage }
                    },
                    [_vm._v(" here ")]
                  ),
                  _vm._v(" to go back to the Geofence List Page ")
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }