var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "outer-wrapper" },
    [
      _c("multiselect", {
        ref: "dropdown-node",
        attrs: {
          value: _vm.selectedOptions,
          options: _vm.options,
          search: true,
          "max-height": 150,
          openDirection: "bottom",
          multiple: true,
          label: _vm.label,
          "track-by": _vm.trackBy,
          selectLabel: "",
          deselectLabel: "",
          selectedLabel: _vm.checkmarkEmoji,
          closeOnSelect: false
        },
        on: { input: _vm.updateSelection }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }